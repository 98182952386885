import pixelImage from '../../assets/images/bg-pattern-right(light-mode).svg';
import virginLogo from '../../assets/images/virgin_logo.png';
import lloydsLogo from '../../assets/images/lloyds-bank_logo.png';
import mishconLogo from '../../assets/images/Mishcon_de_Reya_logo.png';
import morsonLogo from '../../assets/images/morson_logo.webp';
import nbcLogo from '../../assets/images/nbcuniversal_logo.png';
import RollsLogo from '../../assets/images/Rolls-Royce_SMR_fairuse_logo.png';
import unileverLogo from '../../assets/images/unilever_logo.png';
import udhscLogo from '../../assets/images/Logo_of_United_Kingdom_Department_of_Health_and_Social_Care.png';
import sercoLogo from '../../assets/images/serco_logo.jpg';
import barclaysLogo from '../../assets/images/barclays_logo.webp';
import ndaLogo from '../../assets/images/nda_logo.png';
import airFranceLogo from '../../assets/images/Air_France_logo.png';
import discoveryLogo from '../../assets/images/Discovery_logo.png';
import gskLogo from '../../assets/images/GSK_logo.png';


const Customers = () => {
  return (
    <div className='flex flex-col items-center gap-10 w-full'>
      <div className='grid grid-cols-1 items-center lg:grid-cols-2 h-[25rem] lg:w-8/12 w-11/12 relative'>
        <div className="flex flex-col gap-5">
            <p className="text-gray-500">~/customers</p>
            <div className="text-4xl lg:text-[4rem] lg:leading-[3.7rem]">
              Meet Our <br/> customers.
            </div>
            <div className="flex flex-col gap-8">
              <p className="lg:text-lg">
                <span className="text-gray-500">
                  ECTC is trusted by thousands of developers 
                  <br className="hidden md:inline"/> 
                  and companies around the world.
                </span>
              </p>
              <div className="flex items-center gap-4 !gap-8">
                <a className="flex space-x-2 text-sm text-gray-500 hover:text-gray-700 lg:text-base"href="https://app.ECTC.co/register">
                  <span className="font-medium hover:underline ">Contact sales</span><span>→</span>
                </a>
              </div>
            </div>
        </div>

        <div className="lg:w-1/2 absolute right-0 hidden lg:block z-0 ">
            <img src={pixelImage} alt="pixel footer graphic"/>
        </div>
      </div>
      <div className='flex flex-col items-center bg-white lg:w-8/12 w-11/12 z-20'>
        <div className='grid lg:grid-cols-4 grid-cols-2 place-content-center place-items-center w-full lg:gap-[4rem] gap-5 lg:mt-10 mt-5'>
          <img src={morsonLogo} className='h-32' alt={`logo`} />
          <img src={discoveryLogo} className='h-32' alt={`logo`} />
          <img src={gskLogo} className='h-32' alt={`logo`} />
          <img src={virginLogo} className='h-32' alt={`logo`} />
          <img src={lloydsLogo} className='h-32' alt={`logo`} />
          <img src={ndaLogo} className='h-32' alt={`logo`} />
          <img src={unileverLogo} className='h-32' alt={`logo`} />
          <img src={mishconLogo} className='h-32' alt={`logo`} />
          <img src={nbcLogo} className='h-32' alt={`logo`} />
          <img src={RollsLogo} className='h-32' alt={`logo`} />
          <img src={udhscLogo} className='h-32' alt={`logo`} />
          <img src={sercoLogo} className='h-32' alt={`logo`} />
          <img src={barclaysLogo} className='h-32' alt={`logo`} />
          <img src={airFranceLogo} className='h-20' alt={`logo`} />
        </div>
      </div>
        
      <div className='flex flex-col items-center w-full py-10 border-t'>
        <div className='flex flex-col lg:items-center gap-3 lg:w-8/12 w-11/12 py-16'>
          <div className='lg:text-[2.5rem] text-center text-2xl'>
            Get the most of your data with ECTC
          </div>
          <p className='text-base md:text-lg mx-auto pt-2 text-center text-gray-500 font-inter lg:mx-0 lg:text-left'>Data-Driven Success Begins with ECTC Consulting</p>
        </div>
      </div>
    </div>
  )
}

export default Customers;
